import { KnownCookies } from 'common-types';
import { createSession } from 'fetcher-session';
import NextErrorComponent from 'next/error';
import { parseCookies } from 'nookies';
import { useMount } from 'react-use';
import ErrorPageImage from '~/components/ErrorPageImage';
import Layout from '~/components/Layout';
import { newRandomImage } from '~/constants/imagesAndVideos';
import { fireWebError } from '~/services/analytics/events/generic';
import { getSentry } from '~/services/sentry/wrapper';
import { CustomNextPage } from '~/typings/custom-next';

const ErrorPage: CustomNextPage<any> = ({
  statusCode,
  image,
  hasGetInitialPropsRun,
  title,
  err,
}) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    getSentry()?.captureException(err, {
      extra: {
        ERROR_PAGE: true,
      },
    });
  }

  useMount(() => fireWebError(statusCode));

  return (
    <Layout container>
      <h1 className="mb-4 mt-12 text-center text-2xl font-semibold">
        {title || 'Something went wrong...'}
      </h1>

      <ErrorPageImage initialImage={image} />
    </Layout>
  );
};

ErrorPage.getInitialProps = async ctx => {
  const errorInitialProps = await NextErrorComponent.getInitialProps(ctx);

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps['hasGetInitialPropsRun'] = true;
  errorInitialProps['image'] = newRandomImage();

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (ctx.res?.statusCode === 404) {
    // Opinionated: do not record an exception in Sentry for 404
    return {
      ...errorInitialProps,
      statusCode: 404,
    };
  }

  if (ctx.err) {
    const session = createSession(ctx);
    getSentry()?.setUser({
      id: session.decodedAccessToken?.external_id,
      username: session.decodedAccessToken?.sub,
    });
    getSentry()?.setExtra(
      'client_id',
      (parseCookies(ctx) as KnownCookies)?.sls_client_id,
    );
    getSentry()?.captureUnderscoreErrorException(ctx.err);

    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  getSentry()?.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${ctx.asPath}`),
  );

  return errorInitialProps;
};

export default ErrorPage;
